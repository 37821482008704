<template>
  <v-container>
    <!-- Modal for Uploading Photos -->
    <UploadPhotoModal
      ref="uploadPhotoModal"
      :isOpen="showModal"
      :mode="uploadMode"
      :label="currentLabel"
      :available="currentSelection"
      :photo="currentPhotos"
      @close="showModal = false"
      @photo-added="handlePhotoAdded"
      @photo-removed="handlePhotoRemoved"
      @photo-edited="handlePhotoEdited"
    />

    <!-- Main Grid Structure -->
    <v-row>
      <v-col>
        <!-- First Section -->
        <div
          class="tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-2 md:tw-gap-8 md:tw-my-3"
        >
          <div class="tw-grid tw-grid-cols-1">
            <!-- Header for List, Availability, and Comments -->
            <div>
              <div class="tw-grid tw-grid-cols-3 tw-justify-items-start">
                <p class="c-gray-text tw-font-bold">List of Items</p>
                <p class="c-gray-text tw-font-bold">Availability</p>
                <p class="c-gray-text tw-font-bold">Comments</p>
              </div>
              <v-divider></v-divider>

              <!-- Form for Checklist -->
              <v-form
                disabled
                v-for="(part, i) in getPartsByPage"
                :key="i"
                class="tw-grid tw-grid-cols-3"
              >
                <p class="tw-self-center">{{ part | formatStatus }}</p>
                <p>
                  <v-radio-group v-model="getChecklistSelection[i]" row>
                    <v-radio label="Yes" value="true"></v-radio>
                    <v-radio label="No" value="false"></v-radio>
                  </v-radio-group>
                </p>
                <p>
                  <v-text-field
                    label="Comment"
                    v-model="getChecklistComments[i]"
                  ></v-text-field>
                </p>
              </v-form>
            </div>
          </div>

          <!-- Second Section -->
          <div class="tw-grid tw-grid-cols-1">
            <div>
              <!-- Header for List, Availability, Comments, and Photos -->
              <div class="tw-grid tw-grid-cols-3 tw-justify-items-start">
                <p class="c-gray-text tw-font-bold md:tw-hidden">
                  List of Items
                </p>
                <p class="c-gray-text tw-font-bold">Availability</p>
                <p class="c-gray-text tw-font-bold">New Comments</p>
                <p class="c-gray-text tw-font-bold tw-ml-10">Photo</p>
              </div>
              <v-divider></v-divider>

              <!-- Form for Items with Photo Upload -->
              <v-form ref="checkoutForm" v-model="valid" lazy-validation>
                <div
                  v-for="(part, i) in getPartsByPage"
                  :key="i"
                  class="tw-grid tw-grid-cols-3"
                >
                  <p class="tw-self-center md:tw-hidden">
                    {{ part | formatStatus }}
                  </p>
                  <p class="tw-justify-self-center">
                    <v-radio-group
                      v-model="newSelection[i]"
                      row
                      required
                      :rules="[(v) => !!v || `${part} is required`]"
                    >
                      <v-radio label="Yes" value="true"></v-radio>
                      <v-radio label="No" value="false"></v-radio>
                    </v-radio-group>
                  </p>
                  <p>
                    <v-text-field
                      label="Comment"
                      v-model="newComments[i]"
                    ></v-text-field>
                  </p>

                  <!-- Photo Actions -->
                  <div
                    class="tw-flex tw-gap-2 tw-items-center tw-justify-center"
                  >
                    <template
                      v-if="newPhotos[part] && newPhotos[part].length > 0"
                    >
                      <!-- Edit Photo Button -->
                      <v-btn
                        text
                        class="tw-font-roboto tw-text-lg tw-font-bold"
                        color="#00A36C"
                        @click="
                          openUploadModal({
                            label: part,
                            photos: newPhotos[part],
                            mode: 'edit',
                          })
                        "
                      >
                        Edit Photo
                      </v-btn>
                    </template>
                    <template v-else>
                      <!-- Upload and Camera Buttons -->
                      <v-btn
                        icon
                        @click="
                          openUploadModal({
                            label: part,
                            mode: 'upload',
                          })
                        "
                      >
                        <v-icon color="#114CAC" medium>mdi-cloud-upload</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click="
                          openUploadModal({
                            label: part,
                            mode: 'camera',
                          })
                        "
                      >
                        <v-icon color="#114CAC" medium>mdi-camera</v-icon>
                      </v-btn>
                    </template>
                  </div>
                </div>
              </v-form>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- Photo Display Section -->
    <br />

    <PhotoDisplay
      :checklist_photos="checklistPhotos"
      title="Checkout Photos"
      comment="The photos uploaded here are key during the arrival of the vehicle in the assessment center until the vehicle leaves through this checkout process. Kindly record as you confirm all that is necessary."
      @view-photo="openPhotoViewer"
      @edit-photo="openPhotoEditor"
      @delete-photo="handlePhotoRemoved"
    />

    <br />
    <br />

    <!-- Navigation Buttons -->
    <v-row>
      <v-col class="tw-grid tw-grid-cols-2 tw-justify-items-center">
        <v-btn
          v-if="this.step > 1"
          class="tw-w-1/2"
          @click="back"
          color="primary"
        >
          Back
        </v-btn>
        <v-btn
          class="tw-w-1/2"
          @click="submit"
          v-if="this.step === 4"
          color="warning"
        >
          Submit
        </v-btn>
        <v-btn class="tw-w-1/2" v-else @click="forward" color="primary">
          Next
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { formatStatus } from '../../../utils/helpers'
import UploadPhotoModal from './UploadPhotoModal.vue'
import PhotoDisplay from './UploadedPhotos.vue'

export default {
  components: { UploadPhotoModal, PhotoDisplay },
  props: {
    getPartsByPage: Array,
    getChecklistSelection: Array,
    step: Number,
    getChecklistComments: Array,
  },
  data() {
    return {
      showModal: false,
      uploadMode: '',
      currentLabel: '',
      currentSelection: false,
      newPhotos: {},
      newSelection: [],
      newComments: [],
      valid: true,
      checklistPhotos: [],
      currentPhotos: null,
    }
  },
  methods: {
    handlePhotoRemoved({ label, photo }) {
      if (this.newPhotos[label]) {
        this.newPhotos[label] = this.newPhotos[label].filter(
          (existingPhoto) =>
            existingPhoto.image_base_64 !== photo.image_base_64 &&
            existingPhoto.image_url !== photo.image_url
        )

        if (this.newPhotos[label].length === 0) {
          delete this.newPhotos[label]
        }

        this.updateChecklistPhotos()
      }
    },

    // eslint-disable-next-line no-unused-vars
    openPhotoViewer(_photos) {},

    openPhotoEditor({ label, photos }) {
      // Set data for editing
      this.uploadMode = 'edit'
      this.currentLabel = label
      this.currentSelection = photos[0]?.available || false

      const photoToEdit = photos[0]
      this.currentPhotos = {
        label,
        image_base_64: photoToEdit.image_base_64 || photoToEdit.image_url,
        image_url: photoToEdit.image_url || '',
        available: photoToEdit.available || false,
        comment: photoToEdit.comment || '',
      }
      this.showModal = false
      this.$nextTick(() => {
        this.showModal = true
      })
    },

    updateChecklistPhotos() {
      this.checklistPhotos = Object.keys(this.newPhotos).map((label) => ({
        label,
        photos: this.newPhotos[label],
      }))
    },

    handlePhotoAdded({ label, photos }) {
      if (!this.newPhotos[label]) {
        this.newPhotos[label] = []
      }

      const newPhotoEntries = photos.map((photo) => ({
        label,
        comment: photo.comment || 'No comment provided',
        image_url: photo.image_url || '',
        image_base_64: photo.image_base_64 || '',
        available: this.currentSelection,
      }))

      this.newPhotos[label] = newPhotoEntries
      this.updateChecklistPhotos()
    },

    handlePhotoEdited({ label, photo }) {
      if (!this.newPhotos[label]) {
        return
      }

      this.newPhotos[label] = [photo]
      this.updateChecklistPhotos()
    },

    openUploadModal({ label, photos = [], mode }) {
      const itemIndex = this.getPartsByPage.indexOf(label)
      this.currentSelection = this.newSelection[itemIndex]

      this.uploadMode = mode || 'edit'
      this.currentLabel = label

      if (mode === 'edit' && photos.length > 0) {
        const photoToEdit = photos[0]
        this.currentPhotos = {
          label,
          image_base_64: photoToEdit.image_base_64 || photoToEdit.image_url,
          image_url: photoToEdit.image_url || '',
          available: photoToEdit.available || false,
          comment: photoToEdit.comment || '',
        }
      } else {
        this.currentPhotos = null
      }

      this.showModal = false
      this.$nextTick(() => {
        this.showModal = true
      })
    },

    forward() {
      const checklistPhotos = Object.keys(this.newPhotos).flatMap(
        (label) => this.newPhotos[label]
      )

      const payload = [this.newSelection, this.newComments, checklistPhotos]

      if (this.$refs.checkoutForm.validate()) {
        this.$emit('forward-page', payload)
      } else {
        this.$toasted.error('Fill highlighted fields', { duration: 3000 })
      }
    },

    back() {
      this.$emit('back-page')
    },

    submit() {
      const checklistPhotos = Object.keys(this.newPhotos).flatMap(
        (label) => this.newPhotos[label]
      )

      const payload = [this.newSelection, this.newComments, checklistPhotos]

      if (this.$refs.checkoutForm.validate()) {
        this.$emit('submit', payload)
      } else {
        this.$toasted.error('Fill highlighted fields', { duration: 3000 })
      }
    },
  },
  filters: {
    formatStatus,
  },
}
</script>

<style scoped>
.tw-w-full {
  width: 100%;
}

.tw-overflow-hidden {
  overflow: hidden;
}
</style>
